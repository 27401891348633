import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    TextField, // Import TextField for the input fields
    Button, // Import Button for the submit button
    Typography, AccordionSummary, Accordion, AccordionDetails, IconButton, Grid, Snackbar, Alert // Import Typography for displaying the offer name as a title
} from '@mui/material';
import axios from "axios";
import {Delete, ExpandMore} from "@mui/icons-material";
import DateRangePickerComponent from "./DateRangePickerComponent";
import dayjs from "dayjs";

const KeywordsTable = ({domain, hashId}) => {
    const [data, setData] = useState({});
    const [offers, setOffers] = useState([]);
    const [summary, setSummary] = useState({});
    const [dateFrom, setDateFrom] = useState(dayjs().subtract(7, 'days').format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(dayjs().format('YYYY-MM-DD'));
    const [loadingInsights, setLoadingInsights] = useState(false);

    const [dates, setDates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [snackbarMessage, setSnackbarMessage] = useState(''); // Message to display in Snackbar
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Severity of the Snackbar message
    const [openSnackbar, setOpenSnackbar] = useState(false); // State to control Snackbar visibility

    const [expanded, setExpanded] = useState(false); // State to control which accordion is expanded



    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleCheckInsights = () => {
        console.log('Checking insights');
        setLoadingInsights(true);
        axios.get(`${process.env.REACT_APP_API_URL}/insights`, {params: {domain, dateTo, dateFrom}})
            .then(response => {
                console.log(response.data);
                setData(response.data);
                setLoadingInsights(false);
            })
            .catch(error => {
                setLoadingInsights(false);
                console.error('Error fetching insights:', error);
            });
    }


    const SummaryTable = () => {
        return (
            <>
                <DateRangePickerComponent
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    onSubmit={handleCheckInsights}
                />
                {loadingInsights ? (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Keyword</TableCell>
                                    <TableCell align="right">Revenue</TableCell>
                                    <TableCell align="right">Clicks</TableCell>
                                    <TableCell align="right">EPC</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {summary && Object.keys(summary).length > 0 ? (
                                    Object.entries(summary).map(([category, details]) => (
                                        <React.Fragment key={category}>
                                            <TableRow>
                                                <TableCell rowSpan={Object.keys(details.keywords).length + 2}>
                                                    <Typography variant="h6">{category}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            {Object.entries(details.keywords).map(([keyword, subdetails]) => (
                                                <TableRow key={keyword}>
                                                    <TableCell>{keyword}</TableCell>
                                                    <TableCell align="right">{subdetails.revenue}</TableCell>
                                                    <TableCell align="right">{subdetails.clicks}</TableCell>
                                                    <TableCell align="right">{subdetails.epc}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell><strong>Subtotal</strong></TableCell>
                                                <TableCell align="right"><strong>{details.total_revenue}</strong></TableCell>
                                                <TableCell align="right"><strong>{details.total_clicks}</strong></TableCell>
                                                <TableCell align="right"><strong>{details.average_epc}</strong></TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </>
        );
    };


    const testUrl = (url) => {
        let decodedUrl = url
        if (/%(?![0-9a-fA-F]{2})/.test(url)) {
            console.log("The URL seems to be improperly encoded.");
        } else if (/%[0-9a-fA-F]{2}/.test(url)) {
            console.log("The URL is encoded. Decoding...");
            decodedUrl = decodeURIComponent(url);
            console.log("Decoded URL:", decodedUrl);
        } else {
            console.log("The URL does not appear to be encoded.");
        }

        return decodedUrl
    }


    const addVariation = () => {
        // Add a new variation with 5 empty strings (representing 5 new text inputs)
        setVariations([...variations, Array(5).fill("")]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const queryStrings = variations.map(variant =>
                "terms=" + variant.filter(kw => kw.trim() !== "").join(",")
            );
            const url = new URL(offers[0].url);
            const params = new URLSearchParams(url.search);
            params.delete('terms');
            url.search = params.toString();

            const dataToBeSent = {
                workspace_id: null,
                url: testUrl(url.toString()),
                queryStringRotation: queryStrings
            };

            console.log(dataToBeSent)

            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/offer/${offers[0]._id}`, {data: dataToBeSent});

            // On success, show success message
            setSnackbarMessage('Variations saved successfully!');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            setExpanded(false); // Close all accordions

            // Optionally, invoke onSave callback if provided

        } catch (error) {
            // On error, show error message
            setSnackbarMessage('Failed to save variations. Please try again.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            setExpanded(false); // Close all accordions
        }
    };

    const deleteVariation = (variantIndex) => {
        if (variations.length > 1) {
            const newVariations = variations.filter((_, index) => index !== variantIndex);
            setVariations(newVariations);
        }
    };



    const [variations, setVariations] = useState([]);

    useEffect(() => {
        // Initialize variations from offers or with a default value
        const initialVariations = offers && offers.length > 0 && offers[0].queryStringRotation && offers[0].queryStringRotation.length > 0
            ? offers[0].queryStringRotation.map(item => item.replace("terms=", "").split(",")) // Handle queryStringRotation if available
            : offers && offers.length > 0 && offers[0].keywords && offers[0].keywords.length > 0
                ? [offers[0].keywords] // Use keywords as a single variation if available
                : [[]]; // Start with an empty variation if neither is available

        setVariations(initialVariations);
    }, [offers]);



    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/insights`, {params: {domain, dateTo, dateFrom}});
                console.log(response.data.summary)
                setSummary(response.data.summary)
                const offersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/campaigns/offers/${hashId}`);
                setOffers(offersResponse.data); // Make sure to set the state correctly for offers


            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [domain, hashId]); // Added domain and hashId as dependencies

    return (
        <>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="250px">
                    <CircularProgress/>
                </Box>
            ) : (
                <>

                    <Box key={offers[0]._id} mb={2}>


                        <form onSubmit={handleSubmit}>
                            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                                    {snackbarMessage}
                                </Alert>
                            </Snackbar>

                            <Typography variant="h6" color="primary" gutterBottom sx={{mb: 2}}>
                                Offer Name - {offers[0].name}
                            </Typography>
                            {variations.map((variantKeywords, variantIndex) => (
                                <Accordion
                                    key={variantIndex}
                                    expanded={expanded === `panel${variantIndex}`}
                                    onChange={handleAccordionChange(`panel${variantIndex}`)}
                                >
                                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`panel${variantIndex}a-content`} id={`panel${variantIndex}a-header`}>
                                        <Typography>Variant {variantIndex + 1}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2} alignItems="flex-end">
                                            {variantKeywords.map((keyword, index) => (
                                                <Grid item xs={11} key={`${variantIndex}-${index}`}> {/* Adjusted for better space management */}
                                                    <TextField
                                                        name={`keyword_${variantIndex}_${index}`}
                                                        label={`Keyword ${index + 1}`}
                                                        value={keyword}
                                                        onChange={e => {
                                                            const newVariations = [...variations];
                                                            newVariations[variantIndex][index] = e.target.value;
                                                            setVariations(newVariations);
                                                        }}
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            ))}
                                            {variations.length > 1 && (
                                                <Grid item xs={1} style={{ textAlign: 'right' }}> {/* Adjusted for delete button */}
                                                    <IconButton onClick={() => deleteVariation(variantIndex)} aria-label="delete" size="small" style={{ color: 'red' }}>
                                                        <Delete />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                            <Button type="button" onClick={addVariation} variant="contained" color="secondary" style={{ marginTop: '16px' }}>
                                Add Variation
                            </Button>
                            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px', marginLeft: '8px' }}>
                                Save
                            </Button>
                        </form>
                    </Box>




                    <SummaryTable />


                </>
            )}
        </>
    );
};

export default KeywordsTable;
