import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Alert, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from "./Logo";

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // State to store the error message
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent the form from refreshing the page

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, { username, password });
      localStorage.setItem('token', response.data.token);
      navigate('/'); // Navigate to the '/' route
    } catch (error) {
      console.error(error);
      setError('Invalid username or password'); // Set the error message
    }
  };

  return (
    <Container maxWidth="xs">
        <Logo />
        <form onSubmit={handleLogin}>
          <TextField
            value={username}
            onChange={e => setUsername(e.target.value)}
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit">
              Login
            </Button>
          </Box>
          {error && <Alert severity="error">{error}</Alert>} {/* Display the error message */}
        </form>
    </Container>
  );
}

export { Login };