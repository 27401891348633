import {Container, Typography} from "@mui/material";
import CampaignsTable from "./CampaignsTable";
import Layout from "./Layout";

const Campaigns = () => {
    return (
        <Layout>
            <Container sx={{mt: 3}} maxWidth={"3xl"}>
                <Typography variant={"h4"} color={"primary"} sx={{mb: 2}}>Campaigns</Typography>
                <CampaignsTable/>
            </Container>
        </Layout>
    )
}

export default Campaigns;