import React, {useEffect, useState} from 'react';
import CampaignSelector from './CampaignSelector';
import HeadlineSelector from './HeadlineSelector';
import ImageSelector from './ImageSelector';
import SubmitButton from './SubmitButton';
import {CircularProgress, Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material';
import {jwtDecode as jwt_decode} from 'jwt-decode';
import MatrixGridComponent from "./MatrixGridComponent";
import {AccountCircle, Logout} from "@mui/icons-material";
import {
    Alert,
    Box, Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import axios from "axios";
import io from 'socket.io-client';
import MidJourneyStyleSelector from "./MidjourneyStyleSelector";
import InstructionsComponent from "./InstructionsComponent";
import logo from '../images/typ.png'
import S3Uploader from "./s3";
import LogViewer from "./Logviewer";
import TaboolaAccounts from "./TaboolaAccounts";
import {useNavigate} from "react-router-dom";
import Logo from "./Logo";
import KeywordsInput from "./Keywords";
import LanguageSelector from "./LanguageSelector";
import CountriesList from "./CountriesList";
import CampaignsTable from "./CampaignsTable";
import Layout from "./Layout";
import SpyAdsComponent from "./SpyAdsComponent";
import {object} from "promisify";

import {useParams} from 'react-router-dom';


function Creation() {
    const navigate = useNavigate();

    const {id} = useParams();
    const [campaign, setCampaign] = useState('');
    const [campaigns, setCampaigns] = useState([]);

    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingClone, setLoadingClone] = useState(false);

    const [loadingHeadlines, setLoadingHeadlines] = useState(false);
    const [loadingImagePrompt, setLoadingImagePrompt] = useState(false);
    const [taboolaAccount, setTaboolaAccount] = useState('');
    const [language, setLanguage] = useState('English');
    const [loadingRecalculateHeadlines, setLoadingRecalculateHeadlines] = useState(false);


    const [selectedInstructionHeadlines, setSelectedInstructionHeadlines] = useState('');


    const [loadingCreateCampaign, setLoadingCreateCampaign] = useState(false);

    const [loadingImages, setLoadingImages] = useState(false);
    const [CPAGoal, setCPAGoal] = useState("1");
    const [countries, setCountries] = useState([]);

    const [logMessages, setLogMessages] = useState([]);
    const [spyAds, setSpyAds] = useState([]);
    const [startDone, setStartDone] = useState(false);

    const [keywords, setKeywords] = useState(Array(5).fill(''));


    // Function to add a new log message
    const addLogMessage = (message) => {
        const now = new Date();
        const timestamp = now.toISOString().split('T')[1].slice(0, 8); // Format: HH:MM:SS
        const timedMessage = `${timestamp} - ${message}`;
        setLogMessages(prevMessages => [...prevMessages, timedMessage]);
    };

    const [selectedHeadlineGuideline, setSelectedHeadlineGuideline] = useState('');
    const [headlines, setHeadlines] = useState([]);
    const [selectedHeadlines, setSelectedHeadlines] = useState(new Set());

    const [offers, setOffers] = useState([]);

    const [description, setDescription] = useState('');
    const [imagePrompt, setImagePrompt] = useState('');
    const [selectedCards, setSelectedCards] = useState([]);
    const [campaignTitle, setCampaignTitle] = useState('');
    const [campaignBudget, setCampaignBudget] = useState(20);
    const [campaignDevice, setCampaignDevice] = useState(['PHON']);
    const [topic, setTopic] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [showVariations, setShowVariations] = useState(false);
    const [loadingStart, setLoadingStart] = useState(false);
    const [selectedStyles, setSelectedStyles] = useState([]);
    const [user, setUser] = useState(null); // State to store the user's data

    const [openDialog, setOpenDialog] = useState(false);


    // Function to update state based on user input
    const handleTitleChange = (event) => {
        setCampaignTitle(event.target.value);
    };

    const [images, setImages] = useState([]);


    useEffect(() => {
        setCampaignTitle(generateCampaignName);
        console.log(user)


    }, [topic, campaign]);


    const getOfferInfo = async (id) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/campaigns/${id}`)
            .then(response => {
                const campaignData = response.data.Task.data
                console.log(campaignData)
                setCountries([campaignData.country])
                //getting the third element of the array of the string split by -
                setTopic(campaignData.campaignName.split('-')[2].trim())
                setOffers(campaignData.offers)
                setKeywords(extractKeywords(campaignData.offers[0].url, campaignData.offers[0].provider))

                const flattenedAds = campaignData.ads.map(adGroup => Object.values(adGroup)[0]);

                // Extract titles and thumbnail URL

                setDescription(flattenedAds[0].description)
                setTaboolaAccount(campaignData.taboolaAccount)
                setCampaignBudget(campaignData.budget)
                setCampaignDevice([campaignData.device])
                setStartDone(true)

            })
            .catch(error => console.error('Error fetching campaign:', error));

        await axios.get(`${process.env.REACT_APP_API_URL}/taboola/${id}/items`)
            .then(response => {
                console.log(response.data.results)

                const adsFromTaboola = response.data.results

                const titles = adsFromTaboola.map(ad => ad.title);
                const thumbnailURLs = adsFromTaboola.map(ad => ad.thumbnail_url);

                // Filter for unique titles and thumbnail URLs
                const uniqueTitles = Array.from(new Set(titles));
                const uniqueThumbnailURLs = Array.from(new Set(thumbnailURLs));


                // Directly use the unique arrays without unnecessary mapping
                setHeadlines(uniqueTitles);
                setSelectedHeadlines(new Set(uniqueTitles));
                setImages(uniqueThumbnailURLs);
                setSelectedImages(uniqueThumbnailURLs);
                console.log(adsFromTaboola)
                const transformedAds = adsFromTaboola.map(ad => ({
                    "headline": ad.title,
                    "imageUrl": ad.thumbnail_url,
                    "description": ad.description
                }));
                setSelectedCards(transformedAds);
            })
            .catch(error => console.error('Error fetching Taboola ads:', error));
    }

    useEffect(() => {
        if (id !== undefined) {
            setLoadingClone(true)
            console.log('id', id)
            getOfferInfo(id).then(r => setLoadingClone(false)).catch(error => {
                console.log('error', error)
                setError(error)
                setLoadingClone(false)
            })

        }
    }, [id]);


    const addMacros = (updatedOffers) => {
        // Add macros to the offer URLs
        setOffers(updatedOffers?.map(offer => {
            let url = offer.url;
            if (offer.provider !== "Custom") {
                url = url.split('?')[0]; // Get the base url without params
                if (!url.startsWith('https://')) {
                    url = 'https://' + url;
                }
                url = url + getMacros(offer.provider);
            }
            return {
                ...offer,
                url: url
            };
        }));

    }

    useEffect(() => {


        setOffers(offers.map(offer => {
            let url = offer.url;
            if (offer.provider !== "Custom") {
                url = url.split('?')[0]; // Get the base url without params
                if (!url.startsWith('https://')) {
                    url = 'https://' + url;
                }
                url = url + getMacros(offer.provider);
            }
            return {
                ...offer,
                url: url
            };
        }));

        console.log(offers)


    }, [keywords]);

    const extractKeywords = (url, provider) => {
        //provider can be ads.com, sedo, system1, tonic, with sedo becareful because it has param kw and kwhl
        // extract the keywords from the url using the logic of each one
        // decoded url
        // return an array of keywords
        switch (provider) {
            case 'Tonic.com':
                return url.split('&').filter(param => param.startsWith('kw')).map(param => decodeURIComponent(param.split('=')[1]));
            case 'Ads.com':
                return url.split('&').filter(param => param.startsWith('terms'))[0].split('=')[1].split(',');
            case 'System1':
                return url.split('&').filter(param => param.startsWith('forceKey')).map(param => decodeURIComponent(param.split('=')[1]));
            case 'Sedo.com':
                //be careful with , ignore kwl we need only kw, starts with startsWith('kw{number}')
                //decode the keyword
                return url.split('&').filter(param => /^kw\d+/.test(param)).map(param => decodeURIComponent(param.split('=')[1]));

            default:
                return []
        }
    }

    const getMacros = (provider) => {
        const formattedString = keywords.map((keyword, index) => `kw${index + 1}=${keyword.replace(/\s/g, '%20')}`).join('&');
        const keys = ['forceKeyA', 'forceKeyB', 'forceKeyC', 'forceKeyD', 'forceKeyE'];
        const system1Keywords = keywords.slice(0, 5).map((keyword, index) => `${keys[index]}=${keyword.replace(/\s/g, '%20')}`).join('&');
        switch (provider) {
            case 'Tonic.com':
                return `?subid4={cf_click_id}&click_id={external_id}&network={traffic_source_name}&site={trackingField1}&siteid={trackingField2}&adtitle={trackingField3}&subid2={trackingField5}&${formattedString}`
            case 'Ads.com':
                return `?ref_adnetwork=Taboola&ref_pubsite={trackingField1}&ref_keyword={trackingField3}&subid1={cf_click_id}&terms=${keywords.join(',')}`
            case 'System1':
                return `?ref=taboola-{trackingField1}&sub_id={cf_click_id}&sub_id2={trackingField8}|{trackingField3}&rskey={trackingField3}&${system1Keywords}&impression_track_url=https%3A%2F%2Fsearchgw.com%2Fcf%2Fcv%3Fclick_id%3D%7Bcf_click_id%7D%26payout%3D0%26ct%3Dland&search_track_url=https%3A%2F%2Fsearchgw.com%2Fcf%2Fcv%3Fclick_id%3D%7Bcf_click_id%7D%26payout%3D0%26ct%3Dsearch&click_track_url=https%3A%2F%2Fsearchgw.com%2Fcf%2Fcv%3Fclick_id%3D%7Bcf_click_id%7D%26payout%3D0%26ct%3Dad_click`
            case 'Sedo.com':
                return `?sub1={cf_click_id}&click_id={external_id}&network=taboola&site={trackingField2}_{trackingField1}&adtitle={trackingField3}&kwhl=en&${formattedString}`
            case 'promo-search':
                return `?q=${topic.replace(/\s/g, '+')}&tid=4088&click_id={cf_click_id}`
            default:
                return ""
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwt_decode(token);
            setUser(decodedToken);
        }


        axios.get(`${process.env.REACT_APP_API_URL}/offer`)
            .then(response => setCampaigns(response.data))
            .catch(error => console.error('Error fetching campaigns', error));


    }, []);


    function generateCampaignName() {
        const now = new Date();

        // Convert the date to the desired format if it's not a string already
        const formattedDate = new Date(now).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        // Return the concatenated string
        return `${user?.username.toUpperCase()} - ${formattedDate} - ${topic} - {device} - {country}`;
    }


    const fetchHeadlines = async (spyAdsResponse) => {

        try {

            const instructions =
                'Rule 1) Whenever a city, country, or region is mentioned in a headline, replace it with the corresponding macro. Use "${city:capitalized}$" for cities, "${country:capitalized}$" for countries, and "${region:capitalized}$" for regions.\n' +
                'RULE 2) Ensure that each revised headline is unique and avoid repeating any headline.' +
                'RULE 3) Max 130 characters.' +
                'RULE 4) We are at year 2024, so change any year for 2024 if it is mentioned in the headline.' +
                'Rule 5) Whenever is possible, use call to actions between "()" like "(See More)", "(Search Here)", "(Find Results)", etc'


            const titles = spyAdsResponse.map(ad => ad.title);
            console.log('titles', titles.length)
            const uniqueTitles = Array.from(new Set(titles));
            console.log('Unique Titles:', uniqueTitles.length);


            const prompt = `
    Return 10 headlines in JSON format: headlines: ['','','']
    The first five headlines should be selected from the provided list after 
    applying these rules: ${instructions}. 
    List: "${uniqueTitles.join(',')}".
    The remaining five should be newly created, 
    relevant to the topic '${topic}', 
    and meticulously follow the tone, syntax, grammar, and meaning 
    of the provided list.  
    Language of the headlines should be ${language}.
`;

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/openAI/sendChat`, {prompt: prompt});
            return JSON.parse(response.data.response);
        } catch (error) {
            console.error('Error fetching headlines:', error);
            return [];
        } finally {

        }
    };


    const fetchSpyAds = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/spy?topic=${topic}`,
        );

        console.log(response.data)
        const creativeData = response.data
        const ads = Object.values(creativeData).map(ad => ({
            title: ad.title,
            imageName: ad.thumbnail,
            keywords: ad.keywords
        }));
        console.log(ads)

        await setSpyAds(ads);

        return ads
    }


    const generateImagePrompt = async () => {
        try {

            console.log(spyAds)

            const imageUrl = spyAds[0].imageName
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/openAI/vision`, {imageUrl});
            setImagePrompt(response.data.response.message.content);


        } catch (error) {
            // Consider more specific error handling based on the error response
            console.error('Error fetching images:', error);
            return [];
        } finally {
            setLoading(false);
        }
    }

    const fetchDescription = async (_headlines) => {

        try {
            console.log('headlines', _headlines)
            const prompt = `Craft a universally appealing, concise description (max 150 characters) for native advertising, drawing from the essence of the following headlines: ${_headlines}
            The description should be catchy, smoothly integrate with content, and be applicable to a broad audience without any locale-specific references or placeholder mentions. It should entice readers organically without feeling like a traditional advertisement. Don't be exaggerated or overpromise.
            Use Language: ${language}
            Return it as a json { description: ''}`
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/openAI/sendChat`, {prompt});
            return JSON.parse(response.data.response);
        } catch (error) {
            console.error('Error fetching headlines:', error);
            return [];
        } finally {

        }
    };


    useEffect(() => {

        if (campaign.country) setCountries([campaign.country])

    }, [campaign]);


    const fetchImages = async () => {
        setLoadingImages(true);


        try {
            let result = spyAds.map(ad => ad.imageName);
            setImages(result)
            let imagesCount = 0;
            let modifiedImagePrompt = imagePrompt.replaceAll('${topic}', topic);
            modifiedImagePrompt = modifiedImagePrompt.replaceAll('${titles}', selectedHeadlines);

            const socket = io(process.env.REACT_APP_API_URL);

            let resolvePromise, rejectPromise;

            // Set up event listeners
            socket.on('progressUpdate', message => {
                console.log('Progress Update:', message);
                addLogMessage(message);
            });

            socket.on('imageGenerated', (data) => {
                console.log(data);
                if (data.urls) {
                    result = [...result, ...data.urls];
                    imagesCount += data.urls.length;
                }
                setImages(result);
                if (imagesCount >= selectedStyles.length * 4) {
                    socket.disconnect();
                    resolvePromise(result); // Resolve the promise when all images are received
                }
            });

            socket.on('error', (errorMessage) => {
                console.error('error', errorMessage);
                socket.disconnect();
                rejectPromise(new Error(errorMessage)); // Reject the promise on error
            });

            // Emit events for each style
            selectedStyles.forEach((style) => {
                socket.emit('generateImage', {'imagePrompt': modifiedImagePrompt, style});
            });

            // Create a promise to wait for images or timeout
            await new Promise((resolve, reject) => {
                resolvePromise = resolve; // Assign resolve function
                rejectPromise = reject;   // Assign reject function

                const timeoutId = setTimeout(() => {
                    socket.disconnect();
                    reject(new Error("Timeout: Images could not be fetched in 2 minutes"));
                }, 120000); // 2 minutes timeout

                // Clear the timeout when the promise is resolved or rejected
                const clearTimer = () => clearTimeout(timeoutId);
                resolvePromise = resolvePromise.bind(null, clearTimer);
                rejectPromise = rejectPromise.bind(null, clearTimer);
            });
        } catch (error) {
            console.error('Error fetching images:', error);
            return [];
        } finally {
            setLoadingImages(false);
            setLogMessages([])
        }
    }


    const handleGenerateImagePrompt = async () => {
        setLoadingImagePrompt(true);
        await generateImagePrompt();
        setLoadingImagePrompt(false);
    }

    const createTask = async () => {

        let data = [];
        console.log(user)
        for (const country of countries) {
            for (const device of campaignDevice) {

                let campaignName = campaignTitle.replaceAll('{country}', country)
                campaignName = campaignName.replaceAll('{device}', device)
                data.push({
                    data: {

                        'campaignName': campaignName,
                        'brandingText': 'Search Ads',
                        'country': country,
                        'device': device,
                        'instructionsHeadlines': 1,
                        'instructionsImages': 1,
                        'styles': 'all',
                        'budget': campaignBudget,
                        'offers': offers,
                        'offerName': campaignName,
                        'taboolaAccount': taboolaAccount,
                        'ads': selectedCards,
                        'user': user.userId
                    }, status: 'pending', userId: user.userId
                })
            }
        }

        try {
            // Send a POST request to the /tasks route with the data
            console.log('data to send: ', data);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, data);
            console.log(response.data); // Log the response data
        } catch (error) {
            console.error('Error inserting tasks:', error);
        }

        return data;

    }


    const handleCreateCampaigns = async (event) => {

        event.preventDefault();
        try {
            await createTask();
        } catch (error) {
            console.error('Error during campaign creation:', error);
        } finally {
            navigate('/success'); // Redirect the user to the success page
        }

    }

    const createCampaign = async (country) => {
        const createCampaignEndpoint = `${process.env.REACT_APP_API_URL}/campaigns/create`;
        try {
            let campaignName = campaignTitle.replaceAll('{country}', country)
            let brandingText = 'Search Ads'
            let device = campaignDevice
            let budget = campaignBudget
            let url = 'https://' + campaign.url + getMacros(campaign.provider)
            console.log(url)
            let offerName = topic

            console.log(campaignName, brandingText, country, device, budget, url, offerName, taboolaAccount)
            // Generate the prompt for the image
            const result = await axios.post(createCampaignEndpoint, {
                campaignName, brandingText, country, device, budget, url, offerName, taboolaAccount
            });


            return result;
        } catch (error) {
            // Consider more specific error handling based on the error response
            console.error('Error Creating Campaign:', error);
            return [];
        } finally {
        }
    }

    const createAds = async (taboolaCampaignId, trackingLink) => {
        try {


            let ads = selectedCards

            const adsCreated = await new Promise((resolve, reject) => {
                const socket = io(process.env.REACT_APP_API_URL);
                console.log('Socket adsCreated')

                socket.emit('createAds', {taboolaCampaignId, 'url': trackingLink, ads, taboolaAccount});

                socket.on('adCreationProgress', (data) => {
                    console.log(data.message);
                    addLogMessage(data.message);
                    // Update the UI with progress
                });

                socket.on('adsCreated', (data) => {
                    console.log(data);
                    socket.disconnect();
                    resolve(data);
                });

                socket.on('error', (errorMessage) => {
                    console.log('error', errorMessage);
                    socket.disconnect();
                    reject(new Error(errorMessage));
                });
            });

            // Validate the images response here if necessary
            return adsCreated;


        } catch (error) {
            // Consider more specific error handling based on the error response
            console.error('Error Creating Campaign:', error);
            return [];
        } finally {
        }
    }


    const onSubmit = async (event) => {

        event.preventDefault();
        console.log('onSubmit')
        setLoading(true)
        setHeadlines([])
        setImages([])
        let fetchedHeadlines = await fetchHeadlines(selectedHeadlineGuideline);
        setHeadlines(fetchedHeadlines.headlines);

        let fetchedDescription = await fetchDescription(fetchedHeadlines.headlines);
        setDescription(fetchedDescription.description);

        await generateImagePrompt();
        setLoading(false)
    }

    const handleGenerateHeadlines = async () => {
        setLoadingHeadlines(true)
        setHeadlines([])

        console.log(selectedHeadlineGuideline, selectedInstructionHeadlines)
        console.log("guidelines headlines:", selectedInstructionHeadlines)
        let fetchedHeadlines = await fetchHeadlines();
        setHeadlines(fetchedHeadlines.headlines);

        let fetchedDescription = await fetchDescription(fetchedHeadlines.headlines);
        setDescription(fetchedDescription.description);
        setLoadingHeadlines(false)
    }

    const handleStart = async (event) => {

        event.preventDefault()
        setLoadingStart(true)
        console.log('handleStart')
        const spyAdsResponse = await fetchSpyAds();
        const spyImages = spyAdsResponse.map(ad => ad.imageName);
        setImages(spyImages)

        let allSpyKeywords = spyAdsResponse.map(item => item.keywords).flat();
        allSpyKeywords = allSpyKeywords.filter(item => item !== undefined)


        console.log('allSpyKeywords', allSpyKeywords)

        const fetchedHeadlines = await fetchHeadlines(spyAdsResponse);
        setHeadlines(fetchedHeadlines.headlines);

        const fetchedKeywords = await fetchKeywords(allSpyKeywords);
        setKeywords(fetchedKeywords.keywords)

        let fetchedDescription = await fetchDescription(fetchedHeadlines.headlines);
        setDescription(fetchedDescription.description);
        setLoadingStart(false)
        setStartDone(true)


    }

    const fetchKeywords = async (headlines) => {


        console.log('headlines fetchKeywords', headlines)

        let prompt = ''

        prompt = `based on the following keywords:

Cheap Storage Units
Cheap Storage Units Near Me
Portable Storage Containers
Storage Units Prices
Affordable Storage Options In {city}
Self-storage Units In {city}
House Foundation Repair
foundation repair near me
foundation repair cost 2022
foundation crack repair
foundation specialist near me
liposuction
weight loss injections
laser fat removal near me
laser fat removal cost
liposuction How much does it cost


Generate 5 similar style keywords for the topic: ${topic}, focus on the keywords with higher earnings per click. Use Language: ${language} Return it in json format: keywords: ['','','', ...]`


        if (headlines.length > 0) {
            prompt = `Return 5 titles from this list,
             exclude repeated,
             and whenever you see a city, replace it for the macro "{city}.
             If the list is less than 5, create the remaining headlines based on the topic: "${topic}", ensuring they match the tone, the meaning, grammar, and vocabulary of those already listed.
             Rule 1) Limit headlines to a maximum of 3-6 words. Condense content significantly, omitting articles if necessary, while retaining key meaningful words.
             Here the list: "${headlines.join(",")}".
             Return it in json format: keywords: ['','','', ...]"`


        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/openAI/sendChat`, {prompt});


            console.log(response.data.response)

            return JSON.parse(response.data.response);
        } catch (error) {
            console.error('Error fetching keywords:', error);
            return [];
        } finally {

        }
    };

    const handleRecalculateHeadlines = async () => {
        setLoadingRecalculateHeadlines(true)
        const fetchedHeadlines = await fetchHeadlines(spyAds);
        setHeadlines(prevHeadlines => {
            // Combine previous headlines with new ones, converting to a Set to remove duplicates, then back to an array
            const combinedUniqueHeadlines = [...new Set([...selectedHeadlines, ...fetchedHeadlines.headlines])];
            return combinedUniqueHeadlines;
        });
        setLoadingRecalculateHeadlines(false)
    }

    const handleRecalculateKeywords = async () => {
        const recalculateKeywords = await fetchKeywords([...selectedHeadlines]);
        setKeywords(recalculateKeywords.keywords)
    }


    if (error) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Full viewport height
                    p: 2, // Padding to ensure content doesn't touch the edges on small screens
                    gap: 2, // Spacing between items
                    textAlign: 'center', // Ensures text is centered if it wraps
                }}
            >
                <Typography variant="h6" component="h2" color="textSecondary"
                            sx={{fontSize: {xs: '1rem', sm: '1.25rem', md: '1.5rem'}}}>
                    An error occurred ...
                </Typography>
                <Typography variant="body1" color="textSecondary"
                            sx={{fontSize: {xs: '0.75rem', sm: '0.875rem', md: '1rem'}}}>
                    Please try again later.
                </Typography>
            </Box>
        );
    }


    if (campaigns.length == 0 || loadingClone) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Full viewport height
                    p: 2, // Padding to ensure content doesn't touch the edges on small screens
                    gap: 2, // Spacing between items
                    textAlign: 'center', // Ensures text is centered if it wraps
                }}
            >
                <CircularProgress/>
                <Typography variant="h6" component="h2" color="textSecondary"
                            sx={{fontSize: {xs: '1rem', sm: '1.25rem', md: '1.5rem'}}}>
                    Loading configuration, it can take a while.
                </Typography>
                <Typography variant="body1" color="textSecondary"
                            sx={{fontSize: {xs: '0.75rem', sm: '0.875rem', md: '1rem'}}}>
                    Sit tight and dream up your next headline grabber!
                </Typography>
            </Box>
        );
    }

    return (
        <Layout>
            <Container sx={{mb: 5}}>

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth={true} maxWidth="lg">
                    <DialogContent>
                        <SpyAdsComponent topic={topic}/>
                    </DialogContent>
                </Dialog>

                <Box sx={{
                    mt: 1,
                    p: 2,
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                }}>
                    <form onSubmit={handleStart}>
                        <Typography variant="h6" gutterBottom>
                            Ads Configuration
                        </Typography>
                        <Typography variant="subtitle1">
                            Choose a Campaign
                        </Typography>

                        <CampaignSelector onSelect={setOffers} offers={offers} campaigns={campaigns}
                                          addMacros={addMacros}/>


                        <Typography variant="subtitle1">
                            What do you want to advertise?
                        </Typography>
                        <TextField
                            fullWidth
                            label="Topic"
                            variant="outlined"
                            margin="normal"
                            required={true}
                            value={topic}
                            onChange={(event) => setTopic(event.target.value)} // Set the onChange handler
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // other props as needed
                        />
                        <Button onClick={() => setOpenDialog(true)} disabled={topic.toString().length <= 4}>Show
                            Inspiration</Button>

                        <Typography variant="subtitle1">
                            Country
                        </Typography>
                        <CountriesList countries={countries} setCountries={setCountries}/>
                        <Typography variant="subtitle1">
                            Language
                        </Typography>
                        <LanguageSelector language={language} setLanguage={setLanguage}/>


                        <Box sx={{mt: 2}}/>
                        <SubmitButton text={'Start ✨'}
                                      isLoading={loadingStart} disabled={false}/>
                    </form>
                </Box>


                {startDone && (
                    <><Box sx={{
                        mt: 3,
                        p: 2,
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                    }}>
                        <Typography variant="h6" gutterBottom>
                            Select Headlines Variations
                        </Typography>

                        <HeadlineSelector topic={topic} headlines={headlines} selectedHeadlines={selectedHeadlines}
                                          setSelectedHeadlines={setSelectedHeadlines}
                                          guidelines={selectedHeadlineGuideline}
                                          setHeadlines={setHeadlines}
                                          healines={headlines}
                        />
                        <SubmitButton text={'Recalculate Headlines'} onClick={handleRecalculateHeadlines}
                                      isLoading={loadingRecalculateHeadlines} disabled={loadingRecalculateHeadlines}/>


                    </Box>

                        <Box sx={{
                            mt: 3,
                            p: 2,
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Description
                            </Typography>

                            <TextField fullWidth={true} value={description}
                                       onChange={(event) => setDescription(event.target.value)} rows={4}></TextField>

                        </Box>


                        <Box sx={{
                            mt: 3,
                            p: 2,
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Keywords
                            </Typography>

                            <KeywordsInput keywords={keywords} setKeywords={setKeywords} topic={topic}
                                           language={language}/>
                            <SubmitButton text={'Recalculate Keywords with the headlines'}
                                          onClick={handleRecalculateKeywords}
                                          isLoading={loadingStart} disabled={selectedHeadlines.size == 0}/>

                        </Box>


                        <Box sx={{
                            mt: 3,
                            p: 2,
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Select Images Variations
                            </Typography>
                            <ImageSelector initialImages={images} selectedImages={selectedImages}
                                           setSelectedImages={setSelectedImages}/></Box></>
                )}


                {selectedImages.length > 0 && selectedHeadlines.size > 0 && (

                    <MatrixGridComponent headlines={selectedHeadlines} images={selectedImages} description={description}
                                         selectedCards={selectedCards} setSelectedCards={setSelectedCards}/>)
                }

                {selectedCards.length > 0 &&

                    <form onSubmit={handleCreateCampaigns}>
                        <Box sx={{
                            mt: 3,
                            p: 2,
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Campaign Configuration
                            </Typography>
                            <Typography variant="subtitle1">
                                Choose your Taboola Account:
                            </Typography>
                            <TaboolaAccounts setTaboolaAccount={setTaboolaAccount} taboolaAccount={taboolaAccount}
                                             user={user}/>

                            <Typography variant="subtitle1">
                                Enter Campaign Title:
                            </Typography>
                            <TextField
                                fullWidth
                                required={true}
                                label="Campaign Title"
                                variant="outlined"
                                margin="normal"
                                value={campaignTitle}
                                onChange={handleTitleChange} // Set the onChange handler
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // other props as needed
                            />


                            <Typography variant="subtitle1" sx={{mt: 2}}>
                                Set Budget:
                            </Typography>
                            <TextField
                                fullWidth
                                label="Budget"
                                variant="outlined"
                                margin="normal"
                                required={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => setCampaignBudget(event.target.value)}
                                type="number"
                                value={campaignBudget}
                                // other props as needed
                            />

                            <Typography variant="subtitle1" sx={{mt: 2}}>
                                Choose Device:
                            </Typography>
                            <FormControl fullWidth margin="normal" required={true}>
                                <InputLabel id="device-select-label">Device</InputLabel>
                                <Select
                                    labelId="device-select-label"
                                    label="Device"
                                    multiple={true}
                                    required={true}
                                    value={campaignDevice}
                                    onChange={(event) => setCampaignDevice(event.target.value)}
                                    // value and onChange according to your state management
                                >
                                    <MenuItem value="PHON">Smartphone</MenuItem>
                                    <MenuItem value="DESK">Desktop</MenuItem>
                                </Select>
                            </FormControl>

                            <Box sx={{mt: 2}}>
                                <SubmitButton isLoading={loadingCreateCampaign}
                                              text={`Create ${countries.length * campaignDevice.length} Campaign/s and ${selectedCards.length * countries.length * campaignDevice.length} ads`}/>

                            </Box>
                        </Box>
                    </form>

                }

            </Container>
        </Layout>
    );
}

export default Creation;
