import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Link,
    IconButton,
    CircularProgress, Tooltip, ButtonGroup, Button, Chip, Stack, Snackbar, Alert, Modal, Box, Typography
} from '@mui/material';
import {Archive, AutoAwesome, CopyAll, Keyboard, RefreshRounded, ToysRounded, Unarchive} from "@mui/icons-material";
import KeywordsTable from "./KeywordsTable";

const CampaignsTable = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(false); // Add a loading state

    const [filter, setFilter] = useState('notArchived'); // Possible values: 'notArchived', 'all'
    const [maxHeight, setMaxHeight] = useState(440); // Default max height

    const [campaignLoadings, setCampaignLoadings] = useState({}); // Object to track loading state of each campaign
    const [toast, setToast] = useState({open: false, message: '', severity: 'info'}); // State for the toast notification

    const [openModal, setOpenModal] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [selectedHashId, setSelectedHashId] = useState(null);


    const handleOpenModal = (url, hashId) => {
        const urlObj = new URL(url);
        const domain = urlObj.hostname;
        // Now you can use the domain and proceed with opening the modal
        console.log("Domain:", domain);
        setSelectedDomain(domain);
        setSelectedHashId(hashId)// Save the data for the selected row
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    function countErrors(campaign) {
        let errorCount = 0;

        if (campaign.ads && campaign.ads.taboola && Array.isArray(campaign.ads.taboola)) {
            for (const ad of campaign.ads.taboola) {
                for (const key in ad) {
                    if (ad[key].error === true) {
                        errorCount++;
                    }
                }
            }
        }

        return errorCount;
    }


    const calculateTotals = () => {
        let visitsTotal = 0;
        let conversionsTotal = 0;
        let costTotal = 0;
        let revenueTotal = 0;
        let profitTotal = 0;

        campaigns.forEach(campaign => {
            visitsTotal += campaign.stats.visits || 0;
            conversionsTotal += campaign.stats.conversions || 0;
            costTotal += campaign.stats.cost || 0;
            revenueTotal += campaign.stats.revenue || 0;
            profitTotal += campaign.stats.profit || 0;
        });

        // Calculate ROAS (Return on Ad Spend)
        const roasTotal = costTotal !== 0 ? revenueTotal / costTotal : 0;

        return {
            visitsTotal,
            conversionsTotal,
            costTotal,
            revenueTotal,
            profitTotal,
            roasTotal
        };
    };


    const totals = calculateTotals();


    const fetchCampaigns = async () => {
        try {
            setLoading(true);
            const campaignsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/campaigns`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`, // Replace with your token retrieval logic
                },
            });


            const statsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/campaigns/stats`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`, // Replace with your token retrieval logic
                },
            });

            // Merge stats with campaigns
            const campaignsWithStats = campaignsResponse.data.map(campaign => {
                // Find the active status of the campaign from idAndIsActiveArray


                return {
                    ...campaign,
                    stats: statsResponse.data.items.find(stat => stat.campaignID === campaign.hashId) || {},
                    isTaboolaActive: campaign.active  // Add isTaboolaActive field to each campaign
                };
            });

            const filteredCampaigns = filter === 'notArchived'
                ? campaignsWithStats.filter(campaign => !campaign.archived)
                : campaignsWithStats;

            setCampaigns(filteredCampaigns);


        } catch (error) {
            console.error('Error fetching campaigns:', error);
        }
        setLoading(false);
    };
    useEffect(() => {


        fetchCampaigns();
    }, [filter]);


    useEffect(() => {
        const calculateMaxHeight = () => {
            // Adjust these selectors to match your layout
            const headerHeight = document.querySelector('header')?.offsetHeight || 0;
            const footerHeight = document.querySelector('footer')?.offsetHeight || 0;
            const navbarHeight = document.querySelector('.navbar')?.offsetHeight || 0; // If you have a navbar

            // Calculate available height, adjust '120' based on your layout's specifics
            const availableHeight = window.innerHeight - headerHeight - footerHeight - navbarHeight - 150;

            console.log(availableHeight)
            setMaxHeight(`${availableHeight}px`);
        };

        // Set initial size
        calculateMaxHeight();

        // Add event listener
        window.addEventListener('resize', calculateMaxHeight);

        // Clean up event listener
        return () => {
            window.removeEventListener('resize', calculateMaxHeight);
        };
    }, []);

    const cellStyles = {
        wordWrap: 'break-word',
        maxWidth: '200px', // adjust as needed
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    };

    // Function to toggle campaign status
    const toggleCampaignStatus = async (campaignId, currentStatus) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/campaigns/update/${campaignId}`, {
                archived: !currentStatus,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            setCampaigns(campaigns.map(campaign =>
                campaign.id === campaignId ? {...campaign, archived: !currentStatus} : campaign
            ));
        } catch (error) {
            console.error('Error updating campaign:', error);
        }
    };

    const optimizeCampaign = async (hashId) => {
        try {
            setCampaignLoadings(prev => ({...prev, [hashId]: true})); // Start loading for specific campaign
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/optimize`, {hashId}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            // Extract the first key in the response data
            const firstKey = Object.keys(response.data)[0];

            // Check if the response has data and the 'sites' array is present
            if (firstKey && response.data[firstKey] && Array.isArray(response.data[firstKey].sites)) {
                const siteCount = response.data[firstKey].sites.length; // Count the number of sites
                setToast({
                    open: true,
                    message: `#${siteCount} sites blacklisted!`,
                    severity: 'success'
                });
            } else {
                setToast({
                    open: true,
                    message: 'No sites blacklisted!',
                    severity: 'info'
                });
            }
            console.log(response);
        } catch (error) {
            console.error('Error updating campaign:', error);
            setToast({open: true, message: 'Failed to optimize campaign.', severity: 'error'});
        } finally {
            setCampaignLoadings(prev => ({...prev, [hashId]: false})); // End loading for specific campaign
        }

    };

    // Function to close the toast
    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToast({...toast, open: false});
    };

    // Function to toggle campaign optimization
    const toggleCampaignOptimization = async (campaignId, currentOptimizeStatus) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/campaigns/update/${campaignId}`, {
                optimize: !currentOptimizeStatus, // Toggle the optimize status
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Optimistically update the UI
            setCampaigns(campaigns.map(campaign =>
                campaign.id === campaignId ? {...campaign, optimize: !currentOptimizeStatus} : campaign
            ));
        } catch (error) {
            console.error('Error updating campaign optimization:', error);
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxWidth: '3xl', // Adjust the size as necessary
        maxHeight: '90vh',
        overflowY: 'auto', // Enable scroll if content is large
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (

        loading ? (
            <CircularProgress/> // Display a loading spinner while loading
        ) : (
            <div>
                <IconButton onClick={fetchCampaigns}>
                    <RefreshRounded/>
                </IconButton>
                <ButtonGroup>
                    <Button
                        color={filter === 'notArchived' ? 'primary' : 'secondary'}
                        onClick={() => setFilter('notArchived')}
                    >
                        Show Not Archived
                    </Button>
                    <Button
                        color={filter === 'all' ? 'primary' : 'secondary'}
                        onClick={() => setFilter('all')}
                    >
                        Show All
                    </Button>
                </ButtonGroup>

                <TableContainer component={Paper} style={{maxHeight: maxHeight}}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Account</TableCell>
                                <TableCell>Taboola ID</TableCell>
                                <TableCell>Visits</TableCell>
                                <TableCell>Conv.</TableCell>
                                <TableCell>CPV</TableCell>
                                <TableCell>vCVR</TableCell>
                                <TableCell>Payout</TableCell>
                                <TableCell>Cost</TableCell>
                                <TableCell>Rev.</TableCell>
                                <TableCell>Profit</TableCell>
                                <TableCell>ROAS</TableCell>
                                {/*<TableCell>Optimize</TableCell>*/}
                                <TableCell>Actions</TableCell> {/* New Header for Actions */}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaigns.map((campaign) => (
                                <TableRow key={campaign.id}>
                                    <TableCell>{campaign.id}</TableCell>
                                    <TableCell style={cellStyles}>
                                        <Tooltip title={campaign.Task.data.campaignName}>
                                            <Link href={campaign.url} target="_blank" rel="noopener noreferrer">
                                                <span>{campaign.Task.data.campaignName}</span>
                                            </Link>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={campaign.Task.data.taboolaAccount}>
                                            <span>{campaign.Task.data.taboolaAccount}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={campaign.isTaboolaActive ? "Active" : "Inactive"}>
                                            <span>
                                                {campaign.isTaboolaActive ? <Chip label={campaign.trafficSourceCampaignIds?.taboola} color="success" size="small"/>
                                                    : <Chip label={campaign.trafficSourceCampaignIds?.taboola} color="error" size="small"/>}
                                            </span>
                                        </Tooltip>
                                    </TableCell>


                                    <TableCell>{campaign.stats.visits}</TableCell>
                                    <TableCell>{campaign.stats.conversions}</TableCell>
                                    <TableCell>{Number(campaign.stats.cpv).toFixed(2)}</TableCell>

                                    <TableCell>{Number(campaign.stats.visitCvr).toFixed(2)}</TableCell>
                                    <TableCell>{Number(campaign.stats.dynamicPayout).toFixed(2)}</TableCell>
                                    <TableCell>{Number(campaign.stats.cost).toFixed(2)}</TableCell>
                                    <TableCell>{Number(campaign.stats.revenue).toFixed(2)}</TableCell>
                                    <TableCell>{Number(campaign.stats.profit).toFixed(2)}</TableCell>


                                    <TableCell>{Number(campaign.stats.roas).toFixed(2)}</TableCell>
                                    {/*<TableCell> <Tooltip*/}
                                    {/*    title={campaign.optimize ? "Disable Optimization" : "Enable Optimization"}>*/}
                                    {/*    <IconButton*/}
                                    {/*        onClick={() => toggleCampaignOptimization(campaign.id, campaign.optimize)}>*/}
                                    {/*        {campaign.optimize ? <span>🛑</span> :*/}
                                    {/*            <span>🚀</span>} /!* You can replace these with suitable icons *!/*/}
                                    {/*    </IconButton>*/}
                                    {/*</Tooltip></TableCell>*/}
                                    <TableCell>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Tooltip title={campaign.archived ? "Unarchive" : "Archive"}>
                                                <IconButton
                                                    onClick={() => toggleCampaignStatus(campaign.id, campaign.archived)}>
                                                    {campaign.archived ? <Unarchive/> : <Archive/>}
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Show Keywords Performance">
                                                <IconButton
                                                    onClick={() => handleOpenModal(campaign.Task?.data?.offers?.[0]?.url, campaign.hashId)}>
                                                    {(campaign.Task?.data?.offers?.[0]?.provider === "Ads.com") ? <Keyboard /> : ''}
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Clone Campaign">
                                                <Link href={`/clone/${campaign.id}`} target="_blank" rel="noopener noreferrer">
                                                <IconButton>
                                                   <CopyAll />
                                                </IconButton>
                                                </Link>
                                            </Tooltip>





                                            {/*<Tooltip title='Force Optimize Campaign'>*/}
                                            {/*    <IconButton onClick={() => optimizeCampaign(campaign.hashId)} disabled={campaignLoadings[campaign.hashId]}>*/}
                                            {/*        {campaignLoadings[campaign.hashId] ? <CircularProgress size={24} /> : <AutoAwesome />}*/}
                                            {/*    </IconButton>*/}
                                            {/*</Tooltip>*/}
                                            <Snackbar open={toast.open} autoHideDuration={6000}
                                                      onClose={handleCloseToast}>
                                                <Alert onClose={handleCloseToast} severity={toast.severity}
                                                       sx={{width: '100%'}}>
                                                    {toast.message}
                                                </Alert>
                                            </Snackbar>

                                            {(() => {
                                                const errorCount = countErrors(campaign);
                                                return errorCount > 0 && (
                                                    <Tooltip title={`${errorCount} ads have not been created`}>
                                                        <Chip
                                                            label={errorCount}
                                                            color="error"
                                                            size="small"
                                                        />
                                                    </Tooltip>
                                                );
                                            })()}
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={3}>Total</TableCell>
                                <TableCell>{totals.visitsTotal}</TableCell>
                                <TableCell>{totals.conversionsTotal}</TableCell>
                                <TableCell></TableCell> {/* CPV - decide how to handle this */}
                                <TableCell></TableCell> {/* vCVR - decide how to handle this */}
                                <TableCell></TableCell> {/* Dynamic Payout - decide how to handle this */}
                                <TableCell>{totals.costTotal.toFixed(2)}</TableCell>
                                <TableCell>{totals.revenueTotal.toFixed(2)}</TableCell>
                                <TableCell>{totals.profitTotal.toFixed(2)}</TableCell>
                                <TableCell>{totals.roasTotal.toFixed(2)}</TableCell>
                                <TableCell></TableCell> {/* Actions column - left empty */}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Modal definition */}
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 2}}>
                            Keywords Performance & Configuration
                        </Typography>
                        <KeywordsTable domain={selectedDomain} hashId={selectedHashId}/>
                    </Box>
                </Modal>

            </div>))


};

export default CampaignsTable;