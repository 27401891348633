import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, AppBar, Toolbar, Typography, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CampaignIcon from '@mui/icons-material/Campaign';
import CreateIcon from '@mui/icons-material/Create';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Icon for logout
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Icon for user display
import {Link, useNavigate} from "react-router-dom";
import {jwtDecode as jwt_decode} from 'jwt-decode';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    marginLeft: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const PersistentSidebar = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();



  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwt_decode(token);
      setUser(decodedToken);
    }
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const logoutUser = () => {
    // Implement logout logic here
    console.log("User logged out");
    // Clear token from localStorage
    localStorage.removeItem('token');
    navigate('/login')
    setUser(null);
  };


  return (
      <div style={{ display: 'flex' }}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              MBTYP.COM
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
            variant="persistent"
            open={open}
            sx={{
              width: drawerWidth,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
              },
            }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerToggle}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <List>
            <ListItem component={Link} to="/campaigns">
              <ListItemIcon><CampaignIcon /></ListItemIcon>
              <ListItemText primary="Campaigns" />
            </ListItem>
            <ListItem component={Link} to="/create">
              <ListItemIcon><CreateIcon /></ListItemIcon>
              <ListItemText primary="Creation" />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon><AccountCircleIcon /></ListItemIcon>
              <ListItemText primary={`Logged in as: ${user?.username.toUpperCase()}`} />
            </ListItem>
            <ListItem button onClick={logoutUser}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          {/* Main content goes here */}
        </Main>
      </div>
  );
};

export default PersistentSidebar;
