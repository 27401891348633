import React from 'react';
import Sidebar from './Sidebar';
import {Box} from "@mui/material";

const Layout = ({ children }) => {
  return (
    <div>
      <Sidebar />
      <main>
        {children}
      </main>
    </div>
  );
};

export default Layout;