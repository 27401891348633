import React from 'react';
import './AdCard.css'; // Ensure this CSS file is linked correctly

const AdCard = ({ headline, imageUrl, description, selected, onSelectCard }) => {
    const selectedClass = selected ? 'selected' : '';

    return (
        <div className={`ad-card ${selectedClass}`} onClick={onSelectCard}>
            <div className="ad-image-container">
                <img src={imageUrl} alt="Ad" className="ad-image" />
            </div>
            <div className="ad-content">
                <h2 className="ad-headline">{headline}</h2>
                <p className="ad-description">{description}</p>
                <div className="ad-footer">
                    <p className="ad-subtext">Sponsored</p>
                    <button className="ad-button">Learn More</button>
                </div>
            </div>
        </div>
    );
};

export default AdCard;
