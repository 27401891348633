import React from 'react';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {Alert} from "@mui/material";

const CampaignCreated = () => {
  const navigate = useNavigate();

  const handleCreateMore = () => {
    navigate('/create');
  };

  const handleSeeCampaigns = () => {
    navigate('/campaigns');
  };

  return (
      <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh', padding: 20 }}
      >
        <Grid item xs={12}>
          <CheckCircleIcon color="success" style={{ fontSize: 60 }} />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Campaign Created Successfully
          </Typography>
          <Alert severity="info">Your campaign has been created successfully it can take up to 15min to appear in your Campaigns list</Alert>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleCreateMore}>
              Create More Campaigns
            </Button>
            <Button variant="contained" color="secondary" onClick={handleSeeCampaigns}>
              See Campaigns
            </Button>
          </Box>
        </Grid>
      </Grid>
  );
};

export default CampaignCreated;
