import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';

const AccountSelect = ({setTaboolaAccount, taboolaAccount, user}) => {
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/taboola/accounts`); // Adjust the URL as needed
                let filteredAccounts = response.data.accounts;

                if (user.username === 'weaveshare') {
                    filteredAccounts = filteredAccounts.filter(account => account.name.includes('MB -3'));
                }

                console.log(filteredAccounts, 'accounts');
                setAccounts(filteredAccounts);
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        fetchAccounts();
    }, []);

    const handleChange = (event) => {
        setTaboolaAccount(event.target.value);
    };

    return (
        <FormControl fullWidth margin={"normal"} required>
            <InputLabel id="account-select-label">Account</InputLabel>
            <Select
                labelId="account-select-label"
                id="account-select"
                required

                value={taboolaAccount}
                label="Account"

                onChange={handleChange}
            >
                {accounts.map(account => (
                    <MenuItem key={account.id} value={account.account_id}>
                        {account.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default AccountSelect;
