import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Alert } from '@mui/material';
import Logo from "./Logo";

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [inviteCode, setInviteCode] = useState(''); // State for the invite code
  const [error, setError] = useState(''); // State to store the error message

    const handleRegister = async () => {
        if (inviteCode !== 'typ.com') {
            setError('Error: Invalid invite code'); // Set the error message
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/signup`, { username, password });
            setError('User created successfully'); // Set the success message
        } catch (error) {
            console.error(error);
            setError('Error creating user'); // Set an error message
        }
    };

  return (
    <Container maxWidth="xs">
        <Logo /> {/* Include the Logo component */}

        <TextField
        value={username}
        onChange={e => setUsername(e.target.value)}
        label="Username"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        value={password}
        onChange={e => setPassword(e.target.value)}
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        value={inviteCode}
        onChange={e => setInviteCode(e.target.value)}
        label="Invite Code"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleRegister}>
        Register
      </Button>
      {error && <Alert severity={error.startsWith('Error') ? 'error' : 'success'}>{error}</Alert>} {/* Display the error message */}
    </Container>
  );
}

export { Register };