import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const SubmitButton = ({ isLoading, text, onClick, disabled }) => {
    // Determine if the button should behave as a submit button or use the onClick handler
    const buttonType = onClick ? 'button' : 'submit';
    const handleClick = onClick ? onClick : undefined;

    return (
        <Button
            variant="contained"
            color="primary"
            type={buttonType}
            onClick={handleClick}
            disabled={isLoading || disabled}
            endIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
            {text}
        </Button>
    );
};

export default SubmitButton;
