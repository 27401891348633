import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from 'axios';

const CampaignSelector = ({ onSelect, offers, campaigns, addMacros }) => {
    const [selectedCampaign, setSelectedCampaign] = useState([]);

    useEffect(() => {
        onSelect(offers);
        setSelectedCampaign(offers);
        console.log(offers);
    }, [offers]);

    const handleChange = (event, newValues) => {
        // Map over all new values to ensure they are objects
        const updatedCampaigns = newValues.map(value => {
            // Check if the value is a string (user entered) and not an object (selected from list)
            if (typeof value === 'string') {
                // Create a new campaign object for the string value
                return {
                    key: value,
                    url: value,
                    provider: 'Custom', // Set a default or custom provider
                    country: 'US' // Set a default or custom country
                };
            } else {
                // It's an object, so just return it
                return value;
            }
        });

        // Update the selected campaigns with the new array of objects
        setSelectedCampaign(updatedCampaigns);
        addMacros(updatedCampaigns);
    };

    return (
        <div>
            <Autocomplete
                multiple
                freeSolo // Enable freeSolo feature
                value={selectedCampaign}
                onChange={handleChange}
                required={!offers.length}
                options={campaigns}
                getOptionLabel={(option) => {
                    // If the option is a string, return it directly
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Otherwise, return the formatted label using the object's properties
                    return option ? `${option.key} - ${option.country} - ${option.provider}` : '';
                }}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.active && (
                            <Box
                                sx={{
                                    width: 10,
                                    height: 10,
                                    borderRadius: '50%',
                                    backgroundColor: 'green',
                                    marginRight: 1
                                }}
                            />
                        )}
                        <Typography>
                            {option ? `${option.key} - ${option.country} - ${option.provider}` : ''}
                        </Typography>
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        fullWidth
                        margin="normal"
                        {...params}
                        label="Select or Enter Campaign URL"
                        required={!offers.length}
                    />
                )}
            />
        </div>
    );
};

export default CampaignSelector;
