import React, {useState, useEffect} from 'react';
import {
    Grid,
    Box,
    CardMedia,
    CardActionArea,
    Card,
    Button,
    IconButton,
    Backdrop,
    Snackbar,
    Alert,
    LinearProgress
} from "@mui/material";
import S3Uploader from "./s3";
import {ImageSearch} from "@mui/icons-material";
import axios from "axios";
import io from "socket.io-client"; // Make sure the path is correct
import { Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText } from "@mui/material";

const ImageSelector = ({setSelectedImages, selectedImages, initialImages}) => {
    const [images, setImages] = useState(initialImages || []);
    const [isBackdropOpen, setIsBackdropOpen] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [currentImageForGeneration, setCurrentImageForGeneration] = useState(null);


    const handleOpenDialog = (imageUrl) => {
        setCurrentImageForGeneration(imageUrl);
        setOpenDialog(true);
    };
    const handleSelectImage = (imageUrl) => {
        setSelectedImages(prevSelectedImages => {
            if (prevSelectedImages.includes(imageUrl)) {
                return prevSelectedImages.filter(img => img !== imageUrl);
            } else {
                return [...prevSelectedImages, imageUrl];
            }
        });
    };

    useEffect(() => {
        setImages(initialImages);
    }, [initialImages]);

    const handleUploadSuccess = (url) => {
        setImages(prevImages => [...prevImages, url]);
    };

    const getFinalPrompt = (prompt, version) => {
        switch (version) {
            case "Original":
                return prompt+" --ar 16:9 --style raw --s 0"
                break;
            case "Natural Enhanced":
                return "Amateur Iphone Shot, instagram selfie style. "+prompt+" Vivid and clear Image.  --ar 16:9 --style raw --s 0"
                break;
            case "Split Screen":
                return "Amateur Iphone Shot, instagram selfie style. Split screen in two images, like before and after. "+prompt+" Vivid and clear Image.  --ar 16:9 --style raw --s 0"
                break;
            default:
                break;
        }
    }

    const generateSimilarImages = async (version) => {
        setOpenDialog(false); // Close the dialog
        const imageUrl = currentImageForGeneration;
        const snackbarId = addSnackbar(imageUrl, version, 'Starting ...');



        setIsBackdropOpen(true); // Open the backdrop
        console.log('Generating similar images for:', imageUrl);
        const imagePrompt = await generateImagePrompt(imageUrl);

        const finalPrompt = getFinalPrompt(imagePrompt, version);

        updateSnackbarMessage(snackbarId, 'Generating images ...')
        console.log(`Generating similar images for ${imageUrl} with prompt: ${finalPrompt}`)
        await fetchImages(finalPrompt, imageUrl, snackbarId);
        setIsBackdropOpen(false); // Open the backdrop
        // Here you can add your logic to generate similar images.
        // For now, it just logs the URL.

        // After generating images, close the backdrop and update the state.
    };

    const generateImagePrompt = async (imageUrl) => {
        try {

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/openAI/vision`, {imageUrl});
            return response.data.response.message.content


        } catch (error) {
            // Consider more specific error handling based on the error response
            console.error('Error fetching images:', error);
            return [];
        } finally {
        }
    }

    const [snackbars, setSnackbars] = useState([]);

    const addSnackbar = (imageUrl, version, initialMessage, severity = 'info') => {
        const id = `${imageUrl}-${version}-${new Date().getTime()}`;
        setSnackbars(prev => [...prev, { id, message: initialMessage, open: true, progress: 0, severity }]);
        return id; // Return the unique id
    };

    const updateSnackbarSeverity = (snackbarId, newSeverity) => {
        setSnackbars(prev => prev.map(snackbar =>
            snackbar.id === snackbarId ? { ...snackbar, severity: newSeverity } : snackbar
        ));
    };

    const updateSnackbarProgress = (id, increment) => {
        setSnackbars(prev => prev.map(snackbar =>
            snackbar.id === id ? { ...snackbar, progress: Math.min(snackbar.progress + increment, 100) } : snackbar
        ));
    };

    const handleError = (id, errorMessage) => {
        addSnackbar(id, errorMessage, 'error');
    };

    const handleSuccess = (id, successMessage) => {
        addSnackbar(id, successMessage, 'success');
    };

    const updateSnackbarMessage = (id, newMessage) => {
        setSnackbars(prev => prev.map(snackbar =>
            snackbar.id === id ? {...snackbar, message: newMessage} : snackbar
        ));
    };

    const closeSnackbar = (snackbarId) => {
        setSnackbars(prev => prev.filter(snackbar => snackbar.id !== snackbarId));

    };

    const handleCloseSnackbar = (index, event, reason) => {
        if (reason === 'clickaway') {
            // Do nothing if the user clicks away
            return;
        }

        // Close the snackbar for other reasons (like pressing the close button)
        setSnackbars(prev => prev.map((snackbar, i) =>
            i === index ? { ...snackbar, open: false } : snackbar
        ));
    };


    const generateDalleImage = async (prompt) => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/openAI/generate-image`, {params: {prompt}});
            console.log(response)
            setImages(prevImages => [...prevImages, response.data.imageUrl]);


        } catch (error) {
            // Consider more specific error handling based on the error response
            console.error('Error fetching images:', error);
            return [];
        } finally {
        }
    }

    const funnyMessages = [
        "Hold on, our AI is still learning to color within the lines!",
        "Brb, teaching the AI the difference between a cat and a dog.",
        "Generating art... If only it was this easy with a paintbrush!",
        "Adding some pizzazz to your image!",
        "Just a moment, our pixels are still aligning themselves.",
        "Our AI is taking a coffee break... Just kidding, loading your image!",
        "Please wait, our digital artist is in the zone.",
        "Almost there... unless the AI decides to start over. Just kidding!",
        "One moment, we're on the verge of an AI masterpiece!",
        "Perfecting the last pixel, hold tight!",
        "Our digital artists are stretching their pixels. Please hold on!",
        "Working hard, or hardly working? We’ll never tell. Your image is on the way!",
        "Just a sec, our AI is still getting its gears greased.",
        "Hang tight, we're teaching the AI not to draw outside the lines.",
        "Did you know? AI dreams of electric sheep while processing images.",
        "Beep boop! Translating AI language into beautiful art...",
        "Our virtual brushes are at work. Bob Ross would be proud!",
        "Conjuring pixels and casting image spells. Magic in progress!",
        "If image generation was an Olympic sport, we’d be going for gold!",
        "On a scale of 1 to 10, we're at a solid 7.5 in generating your image.",
        "Juggling pixels and code – almost done!",
        "Our AI is currently taking a virtual art class. Please hold.",
        "Pixel party in progress! Your image is the guest of honor.",
        "Adjusting the virtual easel, your masterpiece is coming up!",
        "Our AI is currently in a creative brainstorm. Image soon!",
        "Finishing touches underway – like adding sprinkles to a cake!",
        "We're teaching the AI the subtle art of doodling. Stay tuned!",
        "Hold tight, our AI is having a moment of artistic inspiration.",
        "Be patient, our AI is on a pixel hunt!",
        "The AI is currently playing hide and seek with the color palette.",
        "Please hold, our AI is busy blending virtual paint.",
        "Our AI is taking a quick tour of the Louvre for inspiration!",
        "Gearing up the pixel machine – your image is en route!",
        "Our AI is currently debating the merits of impressionism.",
        "Stand by, the AI is busy setting up its digital canvas.",
        "Our AI might be the next digital Picasso, just you wait and see!",
        "We're teaching the AI about the Golden Ratio. Art is coming!",
        "Our AI is busy tuning its virtual violin. Art is music, after all!",
        "One moment, the AI is currently in a digital art exhibition.",
        "Your image is baking in our digital oven. Almost ready!",
        "Our AI is currently navigating the Bermuda Triangle of pixels.",
        "Just a second, our AI is lost in the matrix of creativity.",
        "Our AI is on a coffee run. Decaf or regular for your image?",
        "Please wait, our AI is stuck in a loop of artistic contemplation.",
        "Our AI is currently on a virtual safari, capturing wild pixels.",
        "Hang on, our AI is painting by numbers – and it’s a big number!",
        "Loading your image, now with 10% more whimsy!",
        "Our AI is currently chasing the muse. Almost caught it!",
        "Your image is coming up, just after this short intermission!",
        "Almost there, the AI is applying the final stroke of genius!"
    ];


    const fetchImages = async (imagePrompt, imageUrl, snackbarId) => {

        let timeoutId;

        try {

            const socket = io(process.env.REACT_APP_API_URL);

            let imagesCount = 0;
            let resolvePromise, rejectPromise;
            const randomMessage = funnyMessages[Math.floor(Math.random() * funnyMessages.length)];
            updateSnackbarMessage(snackbarId, randomMessage);

            // Set up event listeners
            socket.on('progressUpdate', message => {
                console.log('Progress Update:', message);
                updateSnackbarProgress(snackbarId, 5);



            });

            socket.on('imageGenerated', (data) => {
                console.log(data);
                if (data.urls.length) {
                    setImages(prevImages => [...prevImages, ...data.urls]);
                    imagesCount += data.urls.length;
                    updateSnackbarMessage(snackbarId, 'Oh la la! Work done, check the images 🤩');
                    updateSnackbarSeverity(snackbarId, 'success')
                    // Close the Snackbar after a delay
                    updateSnackbarProgress(snackbarId, 100);
                    setTimeout(() => closeSnackbar(imageUrl), 5000); // Adjust the delay as needed
                }
                else {
                    updateSnackbarMessage(snackbarId, 'Error generating images 😢');
                    updateSnackbarSeverity(snackbarId, 'error')
                    // Close the Snackbar after a delay
                    updateSnackbarProgress(snackbarId, 100);
                    setTimeout(() => closeSnackbar(snackbarId), 5000); // Adjust the delay as needed
                }

                clearTimeout(timeoutId);

                // Show completion message



                socket.disconnect();
                resolvePromise(images); // Resolve the promise when all images are received

            });

            socket.on('error', (errorMessage) => {
                console.error('error', errorMessage);
                socket.disconnect();
                rejectPromise(new Error(errorMessage)); // Reject the promise on error
            });

            //const midJourneyStyles = ['1kLWvYriu5hh2PPQ', 'Cd3DxKogVWMm','1HqXNQ8YXzQ', 'v6'];

            socket.emit('generateImage', {imagePrompt});

            // Create a promise to wait for images or timeout
            await new Promise((resolve, reject) => {
                resolvePromise = resolve; // Assign resolve function
                rejectPromise = reject;   // Assign reject function

                 timeoutId = setTimeout(() => {
                    socket.disconnect();
                    updateSnackbarMessage(snackbarId, "Timeout: Images could not be fetched in 2 minutes")
                    updateSnackbarSeverity(snackbarId, 'error')
                    updateSnackbarProgress(snackbarId, 100);
                    setTimeout(() => closeSnackbar(snackbarId), 5000); // Adjust the delay as needed


                    reject(new Error("Timeout: Images could not be fetched in 2 minutes"));
                }, 120000); // 2 minutes timeout

                // Clear the timeout when the promise is resolved or rejected
                const clearTimer = () => clearTimeout(timeoutId);
                resolvePromise = resolvePromise.bind(null, clearTimer);
                rejectPromise = rejectPromise.bind(null, clearTimer);
            });
        } catch (error) {
            console.error('Error fetching images:', error);
            return [];
        } finally {
        }
    }

    return (
        <Box sx={{mt: 1}}>
            <Grid container spacing={2}>
                {images.map((image, index) => (
                    <Grid item xs={12} sm={4} md={4} key={`image-${index}`}>
                        <Card onClick={() => handleSelectImage(image)}
                              sx={{border: selectedImages.includes(image) ? '5px solid green' : '5px solid white'}}>
                            <CardActionArea>
                                <CardMedia component="img" height="212" image={image} alt={`Uploaded image ${index}`}/>
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        color: 'white',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        },
                                    }}
                                    onClick={() => handleOpenDialog(image)} // Pass the event and image URL
                                >
                                    <ImageSearch/>
                                </IconButton>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <S3Uploader onUploadSuccess={handleUploadSuccess}/>

            {snackbars.map((snackbar, index) => (
                <Snackbar
                    key={index}
                    open={snackbar.open}
                    autoHideDuration={null}
                    onClose={(event, reason) => handleCloseSnackbar(index, event, reason)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}

                    style={{ bottom: index * 60 }} // Adjust position for each Snackbar
                >
                    <Alert severity={snackbar.severity} action={(snackbar.severity === "error" || snackbar.severity === "success") ? (
                        <Button color="secondary" size="small" onClick={() => handleCloseSnackbar(index)}>
                            Close
                        </Button>
                    ) : null}>
                        {snackbar.message}
                        <LinearProgress variant="determinate" value={snackbar.progress} />
                    </Alert>

                </Snackbar>
            ))}

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Select Version</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem button onClick={() => generateSimilarImages("Original")}>
                            <ListItemText primary="Original Version" />
                        </ListItem>
                        <ListItem button onClick={() => generateSimilarImages("Natural Enhanced")}>
                            <ListItemText primary="Natural Enhanced" />
                        </ListItem>
                        <ListItem button onClick={() => generateSimilarImages("Split Screen")}>
                            <ListItemText primary="Split Screen" />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>


    );
};

export default ImageSelector;
