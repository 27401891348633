import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const DateRangePicker = ({ dateFrom, setDateFrom, dateTo, setDateTo, onSubmit }) => {

    const handleDateFromChange = (newValue) => {
        setDateFrom(dayjs(newValue).format('YYYY-MM-DD'));
    };

    const handleDateToChange = (newValue) => {
        setDateTo(dayjs(newValue).format('YYYY-MM-DD'));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" alignItems="center" gap={2}>
                <DatePicker
                    label="Date From"
                    value={dayjs(dateFrom)}
                    onChange={handleDateFromChange}
                    renderInput={(params) => <TextField {...params} size="small" />}
                    inputFormat="YYYY-MM-DD"
                />
                <DatePicker
                    label="Date To"
                    value={dayjs(dateTo)}
                    onChange={handleDateToChange}
                    renderInput={(params) => <TextField {...params} size="small" />}
                    inputFormat="YYYY-MM-DD"
                />
                <Button variant="contained" color="primary" onClick={onSubmit} style={{ height: 40 }}>
                    Submit
                </Button>
            </Box>
        </LocalizationProvider>
    );
};

export default DateRangePicker;
