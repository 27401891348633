import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function PublicRoute({ children }) {
  const token = localStorage.getItem('token');
  const location = useLocation();

  return token ? <Navigate to="/" replace /> : children;
}

export default PublicRoute;