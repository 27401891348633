import React, { useState } from 'react';
import {Box, Grid, Typography} from "@mui/material";
import AdCard from "./AdComponent";

const MatrixGridComponent = ({ headlines, images, description, setSelectedCards, selectedCards }) => {
    // State to keep track of selected cards

    // Handler for selecting and deselecting cards
    const handleSelectCard = (card) => {
        // Check if the card is already selected
        const isAlreadySelected = selectedCards.some(selectedCard =>
            selectedCard.headline === card.headline && selectedCard.imageUrl === card.imageUrl
        );

        // If it's selected, remove it from the array, otherwise add it
        setSelectedCards(prevSelectedCards =>
            isAlreadySelected
                ? prevSelectedCards.filter(selectedCard =>
                    selectedCard.headline !== card.headline || selectedCard.imageUrl !== card.imageUrl
                )
                : [...prevSelectedCards, card]
        );

    };

    // Function to determine if a card is selected
    const isSelected = (headline, imageUrl) => {
        return selectedCards.some(card => card.headline === headline && card.imageUrl === imageUrl);
    };

    return (
        <Box sx={{
            mt: 3,
            p: 2,
            border: '1px solid #ddd',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        }}>
            <Typography variant="h6" gutterBottom>
                Select Ad variations
            </Typography>
            {headlines.size === 0 && ("No data")}
            <Grid container spacing={2}>
                {[...new Set(headlines)].map((headline, indexHeadline) => (
                    images.map((image, indexImage) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={indexHeadline+"-"+indexImage}>
                        <AdCard
                            headline={headline}
                            imageUrl={image}
                            description={description} // Add your own description logic if needed
                            selected={isSelected(headline, image)}
                            onSelectCard={() => handleSelectCard({
                                headline: headline,
                                imageUrl: image,
                                description: description // Replace with your actual description
                            })}
                        />
                    </Grid>
                    ))
                ))}
            </Grid>
        </Box>
    );
};

export default MatrixGridComponent;
