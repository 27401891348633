import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {Register} from "./components/Register";
import {Login} from "./components/Login";
import {Logout} from "./components/Logout";

import Creation from "./components/creation";
import PrivateWrapper from './PrivateWrapper';
import PublicRoute from './PublicRoute';
import CreationSuccess from "./components/CreationSuccess";
import Campaigns from "./components/Campaigns";

function RoutesComponent() {
    return (
        <Router>
            <Routes>
                <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
                <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/create" element={<PrivateWrapper><Creation /></PrivateWrapper>} />
                <Route path="/clone/:id" element={<PrivateWrapper><Creation /></PrivateWrapper>} />
                <Route path="/campaigns" element={<PrivateWrapper><Campaigns /></PrivateWrapper>} />
                <Route path="/" element={<PrivateWrapper><Campaigns /></PrivateWrapper>} />
                <Route path="/success" element={<PrivateWrapper><CreationSuccess /></PrivateWrapper>} />
                {/* Add more routes as needed */}
            </Routes>
        </Router>
    );
}

export default RoutesComponent;