import React from 'react';
import logo from '../images/typ.png';

function Logo() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
      <img src={logo} alt="Logo" style={{ width: '64px', height: '64px' }} />
    </div>
  );
}

export default Logo;