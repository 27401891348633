import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const S3Uploader = ({ onUploadSuccess }) => {
    const [loading, setLoading] = useState(false);

    const uploadFile = (file) => {
        if (!file) {
            console.error('No file selected.');
            return;
        }

        setLoading(true); // Start loading

        // AWS S3 configuration
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_REGION,
        });

        // Generate a unique file name
        const uniqueFileName = `${uuidv4()}-${file.name}`;

        // S3 upload parameters
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: uniqueFileName,
            Body: file,
            ACL: 'public-read', // Setting the file to be publicly accessible
        };

        // Perform the upload
        s3.upload(params, (err, data) => {
            setLoading(false); // Stop loading
            if (err) {
                console.error('There was an error uploading your file: ', err);
                return;
            }
            // Construct and use the public URL directly
            const publicUrl = getPublicUrl(uniqueFileName);
            onUploadSuccess(publicUrl);
        });
    };

    const getPublicUrl = (fileKey) => {
        const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
        const region = process.env.REACT_APP_AWS_REGION;
        let url;

        if (region === 'us-east-1') { // Special case for US East (N. Virginia) region
            url = `https://${bucketName}.s3.amazonaws.com/${fileKey}`;
        } else {
            url = `https://${bucketName}.s3-${region}.amazonaws.com/${fileKey}`;
        }

        return url;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadFile(file);
        }
    };

    return (
        <Box sx={{ mt: 1 }}>
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="file-upload">
                <Button
                    component="span"
                    variant="contained"
                    startIcon={loading ? <CircularProgress size={24} /> : <CloudUploadIcon />}
                    disabled={loading}
                >
                    {loading ? 'Uploading...' : 'Select and Upload File'}
                </Button>
            </label>
        </Box>
    );
};

export default S3Uploader;
