import React, { useState } from 'react';
import { Button, Checkbox, FormGroup, FormControlLabel, TextField, CircularProgress, Box, IconButton } from '@mui/material';
import { Add, Edit } from "@mui/icons-material";

const HeadlinesSelector = ({ selectedHeadlines, setSelectedHeadlines, headlines, setHeadlines }) => {
    const [loading, setLoading] = useState(false);
    const [newHeadline, setNewHeadline] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [editValue, setEditValue] = useState('');

    const handleSelectHeadline = (headline) => {
        setSelectedHeadlines((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(headline)) {
                newSelected.delete(headline);
            } else {
                newSelected.add(headline);
            }
            return newSelected;
        });
    };

    const handleAddHeadline = () => {
        if (newHeadline.trim() !== '') {
            setHeadlines(prevHeadlines => [...prevHeadlines, newHeadline]);
            setNewHeadline('');
        }
    };

    const handleEditHeadline = (index) => {
        setEditIndex(index);
        setEditValue(headlines[index]);
    };

    const handleSaveEdit = (index) => {
        const updatedHeadlines = [...headlines];
        updatedHeadlines[index] = editValue;

        setHeadlines(updatedHeadlines);

        if (selectedHeadlines.has(headlines[index])) {
            const updatedSelectedHeadlines = new Set(selectedHeadlines);
            updatedSelectedHeadlines.delete(headlines[index]);
            updatedSelectedHeadlines.add(editValue);
            setSelectedHeadlines(updatedSelectedHeadlines);
        }

        setEditIndex(null);
        setEditValue('');
    };

    return (
        <div>
            {loading ? (
                <CircularProgress />
            ) : (
                <FormGroup>
                    {headlines?.map((headline, index) => (
                        <Box key={index} display="flex" alignItems="center">
                            <Checkbox
                                checked={selectedHeadlines.has(headline)}
                                onChange={() => handleSelectHeadline(headline)}
                            />
                            {editIndex === index ? (
                                <TextField
                                    value={editValue}
                                    onChange={(e) => setEditValue(e.target.value)}
                                    onBlur={() => handleSaveEdit(index)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSaveEdit(index);
                                            e.preventDefault(); // Prevent the form from submitting on Enter key
                                        }
                                    }}
                                    autoFocus
                                    fullWidth
                                />
                            ) : (
                                <Box display="flex" flexGrow={1} alignItems="center">
                                    <div
                                        style={{ flexGrow: 1, cursor: "pointer" }}
                                        onClick={() => handleSelectHeadline(headline)}
                                    >
                                        {headline}
                                    </div>
                                    <IconButton onClick={() => handleEditHeadline(index)}><Edit /></IconButton>
                                </Box>
                            )}
                        </Box>
                    ))}
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                        <TextField
                            label="New Headline"
                            variant="outlined"
                            value={newHeadline}
                            size="small"
                            sx={{ flexGrow: 1 }}
                            onChange={(e) => setNewHeadline(e.target.value)}
                            margin="normal"
                        />
                        <Button variant="contained" color="primary" onClick={handleAddHeadline} endIcon={<Add />}>
                            Add
                        </Button>
                    </Box>
                </FormGroup>
            )}
        </div>
    );
};

export default HeadlinesSelector;
