import React, {useEffect} from 'react';
import TextField from '@mui/material/TextField';
import axios from "axios";

const KeywordsInput = ({ keywords, setKeywords, topic, language }) => {


  const handleInputChange = (event, index) => {
      const newKeywords = [...keywords];
      newKeywords[index] = event.target.value;
      setKeywords(newKeywords);
  };

  return (
    <div>
      {keywords.map((keyword, index) => (
        <TextField
          key={index}
          margin={'normal'}
          fullWidth={true}
          label={`Keyword ${index + 1}`}
          variant="outlined"
          value={keyword}
          onChange={(event) => handleInputChange(event, index)}
        />
      ))}
    </div>
  );
};

export default KeywordsInput;